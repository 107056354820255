<template>
    <div class="out">
        <div class="item">
            <div class="item_item item_top">数据统计 <span style="margin-left:0.12rem;color:#626262;">{{format(now_time)}}</span> <span style="margin-left:auto">{{$t('main.remcomm')}}:{{NumFormat(salesman_amount)}}</span> </div>
            <div class="item_item">
                <div class="item_list">
                    <div>老师总数</div>
                    <div class="num">{{parseInt(artist.artist) + parseInt(artist.collector)}} <span>位 ({{artist.artist}}创 {{artist.collector}}藏)</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.taow')}}</div>
                    <div class="num">{{good_num}} <span>件</span></div>
                </div>
            </div>
            <div class="item_item">
                <div class="item_list">
                    <div>{{$t('main.tholderT')}}</div>
                    <div class="num">{{today_collect_num}} <span>次</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.tnwt')}}</div>
                    <div class="num">{{today_good_num}} <span>件</span></div>
                </div>
            </div>
            <div class="item_item">
                <div class="item_list">
                    <div>本月佣金</div>
                    <div class="num">{{NumFormat(month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.lastms')}}</div>
                    <div class="num">{{NumFormat(last_month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
            </div>
            <div class="item_item">
                <div class="item_list">
                    <div>本月总额</div>
                    <div class="num">{{NumFormat(original_month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
                <div class="item_list">
                    <div>{{$t('main.lasmt')}}</div>
                    <div class="num">{{NumFormat(original_last_month)}} <span>{{$t('main.yuan')}}</span></div>
                </div>
            </div>
        </div>
        <div class="button_list">
            <div  @click="isShow = true">财务绑定</div>
            <div @click="apply">紧急提款申请</div>
        </div>
        <div class="item">
            <div class="item_item item_top js_sp">
                <div>{{$t('main.hissta')}}</div>
                <div class="date_list">
                    <svg t="1666167949437" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2510" ><path d="M725.333333 170.666667h74.709334C864.853333 170.666667 917.333333 223.189333 917.333333 288.096V799.893333C917.333333 864.757333 864.832 917.333333 800.042667 917.333333H223.957333C159.146667 917.333333 106.666667 864.810667 106.666667 799.904V288.106667C106.666667 223.242667 159.168 170.666667 223.957333 170.666667H298.666667v-32a32 32 0 0 1 64 0v32h298.666666v-32a32 32 0 0 1 64 0v32z m0 64v32a32 32 0 0 1-64 0v-32H362.666667v32a32 32 0 0 1-64 0v-32h-74.709334A53.354667 53.354667 0 0 0 170.666667 288.096V799.893333A53.301333 53.301333 0 0 0 223.957333 853.333333h576.085334A53.354667 53.354667 0 0 0 853.333333 799.904V288.106667A53.301333 53.301333 0 0 0 800.042667 234.666667H725.333333z m-10.666666 224a32 32 0 0 1 0 64H309.333333a32 32 0 0 1 0-64h405.333334zM586.666667 618.666667a32 32 0 0 1 0 64H309.333333a32 32 0 0 1 0-64h277.333334z" p-id="2511"></path></svg>
                    <div @click="pickTime">{{format(early_time)}}</div>
                    <span>-</span>
                    <div @click="pickTime">{{format(last_show_time)}}</div>
                </div>
            </div>
            <div class="item_item list_top">
                <div>日期</div>
                <div>新增作品数</div>
                <div>{{$t('main.holdertr')}}</div>
            </div>
            <div class="item_item list_item bold">
                <div>{{$t('main.at')}}</div>
                <div>{{good_all}}</div>
                <div>{{collect_all}}</div>
            </div>
            <div class="item_item list_item" v-for="(value , key ,index) in list.good_num" :key="key">
                <div>{{key}}</div>
                <div>{{value.daily}}</div>
                <div>{{list.collect_num[key].daily}}</div>
            </div>
            <div class="more" @click="loadMore" v-show="is_more">{{$t('main.seemore')}}</div>
        </div>
        <div class="password-box pop" v-show="isShow"  @click="isShow = false">
			<div class="pop-in1" @click.stop>
                <img class="close" src="../assets/img/close-icon.png"  @click="isShow = false"/>
				<div class="title">
					<img src="../assets/img/title-left-icon.png" alt="">
					<span>财务绑定</span>
					<img src="../assets/img/title-right-icon.png" alt="">
				</div>
				<div class="con">
					<div class="con-tips">
						{{$t('main.infoT')}}
					</div>
					<div class="pass-input">
                        <div class="">
							<label for="">{{$t('main.copN')}}</label><input v-model="company_name"   placeholder="请输入公司/个人名称" type="text">
						</div>
                        <div class="">
							<label for="">{{$t('main.ctn')}}</label><input v-model="bank_duty_number"  placeholder="请输入公司税号" type="text">
						</div>
						<div class="">
							<label for="">银行名称：</label><input v-model="bank_name"   :placeholder="$t('main.pebankName')" type="text">
						</div>
                        <div class="">
							<label for="">银行卡号：</label><input v-model="bank_account"   :placeholder="$t('main.pebanknum')" type="text">
						</div>
                       
					</div>
					<div class="err-tips" v-show="isErr" >
						<img src="../assets/img/err-tips-icon.png"  alt="">
						{{msg}}
					</div>
					<!-- <div class="agree-box-out">
						<div class="agree-box">
							@input="checkedFlag"
							<input  type="checkbox" value="" id="agree-select"
								:checked="goRegFlag" />
							<label id="" for="agree-select">{{$t('main.agreeC')}}</label>
						</div>
						<div class="agree-text">
							《文字文字文字》
						</div>
					</div> -->
				</div>
				<div class="confirm-btn" @click="upload" >
					{{$t('main.comfirm')}}
				</div>
			</div>

		</div>
         <div class="password-box pop" v-show="isShow1"  @click="isShow1 = false">
			<div class="pop-in1" @click.stop>
                <img class="close" src="../assets/img/close-icon.png"  @click="isShow1 = false"/>
				<div class="title">
					<img src="../assets/img/title-left-icon.png" alt="">
					<span>提款申请</span>
					<img src="../assets/img/title-right-icon.png" alt="">
				</div>
				<div class="con">
					<div class="con-tips">
						注意：提款金额将打款至财务绑定的银行账户，请确保财务信息无误后再申请提现，最低可提现{{minAount}}￥
					</div>
					<div class="pass-input">
                        <div class="">
							<label for="">剩余佣金：</label>
                            <div style="width:70%;">{{NumFormat(salesman_amount)}}</div>
						</div>
						<div class="">
							<label for="">提款佣金：</label><input style="width:1.60rem" v-model="cashoutAmount"   :placeholder="`输入提现金额，≥${minAount}`" type="number">
                            <div @click="cashoutAmount = parseFloat(salesman_amount)" style="color:#9a2a25;margin-left:auto;">全部</div>
						</div>
                       
					</div>
					<div class="err-tips" v-show="isErr1" >
						<img src="../assets/img/err-tips-icon.png"  alt="">
						{{msg1}}
					</div>
					<!-- <div class="agree-box-out">
						<div class="agree-box">
							@input="checkedFlag"
							<input  type="checkbox" value="" id="agree-select"
								:checked="goRegFlag" />
							<label id="" for="agree-select">{{$t('main.agreeC')}}</label>
						</div>
						<div class="agree-text">
							《文字文字文字》
						</div>
					</div> -->
				</div>
				<div class="confirm-btn" @click="upload1" >
					{{$t('main.comfirm')}}
				</div>
			</div>

		</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            now_time:new Date(),
            last_time:new Date(),
            early_time:new Date(),
            last_show_time:new Date(),
            load_time:'',
            salesman_date:'',
            salesman_amount:'',
            artist:{
                artist:0,
                collector:0
            },
            good_num:'',
            today_collect_num:'',
            today_good_num:'',
            month:'',
            last_month:'',
            list:{
                collect_num:{},
                good_num:{}
            },
            is_show_good_num:{},
            page:0,
            loading:false,
            isShow:false,
            bank_name:'',
            bank_account:'',
            bank_duty_number:'',
            original_month:'',
            original_last_month:'',
            msg:'',
            isErr:false,
            collect_all:0,
            good_all:0,
            company_name:'',
            is_more:true,
            isShow1:false,
            isErr1:false,
            msg1:'',
            minAount:100,
            cashoutAmount:''
        }
    },
   async mounted(){
       await this.axios.post('auth/getStatistics').then((res)=>{
            this.artist = res.data.data.artist
            this.good_num = res.data.data.good_num
            this.today_collect_num = res.data.data.today_collect_num
            this.today_good_num = res.data.data.today_good_num
            this.month = res.data.data.month
            this.last_month = res.data.data.last_month
            this.original_month = res.data.data.original_month
            this.original_last_month = res.data.data.original_last_month
            this.salesman_date = res.data.data.salesman_date
            this.salesman_amount = res.data.data.salesman_amount
            this.early_time = new Date(this.salesman_date)
            this.minAount = res.data.data.salesman_min_amount
        })
        this.axios.post('auth/getBankInfo').then((res)=>{
            let sale_info =  res.data.data
            this.sale_info = res.data.data
            this.bank_name = sale_info.bank_name
            this.bank_account = sale_info.bank_account
            this.bank_duty_number = sale_info.bank_duty_number
            this.company_name = sale_info.company_name
        })
        
        this.axios.post('auth/getDateTotal',{start:this.format(this.salesman_date),end:this.format(this.last_show_time)}).then((res)=>{
            this.good_all = res.data.data.good_num
            this.collect_all = res.data.data.collect_num
        })
        this.load_time = this.return_30()
        this.axios.post('auth/getStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
            this.list = res.data.data
            this.list.good_num = this.SortByKeys(this.list.good_num)
            
        })
    },
    methods:{
        SortByKeys(obj){
            let key_list = Object.keys(obj).sort((a,b)=>{return b.localeCompare(a)})
            let new_obj = {}
            for(let i = 0 ; i < key_list.length; i++ ){
                new_obj[key_list[i]] = obj[key_list[i]]
            }
            return new_obj
        },
        format(times){
            let date = new Date(times)
            let year = date.getFullYear()
            let month = this.add0(date.getMonth() + 1)
            let day = this.add0(date.getDate())
            return  year+'-'+ month +'-'+ day
        },
        getTimeList(times){
            let date = new Date(times)
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            return  [year,month,day]
        },
        add0(number){
            if(number < 10){
                return '0' + number
            }else{
                return number
            }
        },
        getMore(){
            if(this.loading){
                return
            }
            this.load_time = ''
            this.is_more = true
            this.load_time = this.return_30()
            this.axios.post('auth/getDateTotal',{start:this.format(this.early_time),end:this.format(this.last_show_time)}).then((res)=>{
                this.good_all = res.data.data.good_num
                this.collect_all = res.data.data.collect_num
            })
            
            this.axios.post('auth/getStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
                let new_list = res.data.data
                new_list.good_num = this.SortByKeys(new_list.good_num)
                this.list.good_num = new_list.good_num
                this.list.collect_num = new_list.collect_num
                this.loading = false
            })
        },
        return_30(){
            if(this.load_time){
                this.last_time = new Date(this.load_time).getTime() - 24*60*60*1000
            }
            let now_time = new Date(this.last_time).getTime()
            let frist_time = new Date(this.early_time).getTime()
            if(now_time - frist_time > 15*24*60*60*1000){
                return now_time - 15*24*60*60*1000
            }else{
                this.is_more = false
                return frist_time
            }
        },
        upload(){
            if(!this.company_name){
                this.msg = this.$t('main.peciname')
                this.isErr = true
                return
            }
            if(!this.bank_name){
                this.msg = '请输入银行名称'
                this.isErr = true
                return
            }else if(!this.bank_account){
                this.msg = '请输入银行卡号'
                this.isErr = true
                return
            }else{
                this.isErr = false
                this.axios.post('auth/saveBankInfo',{company_name:this.company_name,bank_name:this.bank_name,bank_account:this.bank_account,bank_duty_number:this.bank_duty_number}).then((res)=>{
                    if(res.data.code == 0){
                        this.isShow = false
                        weui.toast(this.$t('main.bindsucc'))
                        this.sale_info = {
                            company_name:this.company_name,bank_name:this.bank_name,bank_account:this.bank_account,bank_duty_number:this.bank_duty_number
                        }
                        
                    }else{
                        this.msg = res.data.msg
                        this.isErr = true
                    }
                })
            }
            
        },
        upload1(){
            let reg = new RegExp(/^\d{0,15}(\.\d{1,2})?$/)
            console.log(reg.test(this.cashoutAmount))
             if(!reg.test(this.cashoutAmount)){
                this.msg1 = '提现金额最多小数点后两位'
                this.isErr1 = true
                return
            }
            if(this.cashoutAmount < this.minAount){
                this.msg1 = '提现金额小于最小提现金额'
                this.isErr1 = true
                return
            }
            if(this.cashoutAmount > parseFloat(this.salesman_amount)){
               this.msg1 = '剩余佣金不足'
               this.isErr1 = true
                return
            }
            this.axios.post('auth/salesmanApplyWithdraw',{amount:this.cashoutAmount}).then((res)=>{
                if(res.data.code == 0){
                    weui.alert('提现申请成功，等待工作人员处理，预计3个工作日内到账',()=>{
                        this.$router.go(0)
                    })
                }else{
                    weui.alert(res.data.msg)
                }
            })
        },
        getAll(value , obj){
            Object.keys(obj).forEach((item)=>{
                value+=parseInt(obj[item])
            })
            return value
        },
        loadMore(){
            if(this.loading){
                return
            }
            this.load_time = this.return_30()
            this.axios.post('auth/getStatisticsList',{start:this.format(this.load_time),end:this.format(this.last_time)}).then((res)=>{
                let new_list = res.data.data
                new_list.good_num = this.SortByKeys(new_list.good_num)
                this.list.good_num = {...this.list.good_num,...new_list.good_num}
                this.list.collect_num = {...this.list.collect_num,...new_list.collect_num}
                this.loading = false
            })
        },
        apply(){
            if(this.sale_info.company_name&&this.sale_info.bank_name&&this.sale_info.bank_account){
                this.isShow1  = true
            }else{
                weui.alert('请先绑定财务信息，再提交提款申请')
            }
            
        },
        NumFormat(values) {
                let value = parseFloat(values)
				if (!value) return '0.00'
				value = value.toFixed(2)
				var intPart = Math.trunc(value) // 获取整数部分
				var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
				var floatPart = '.00' // 预定义小数部分
				var value2Array = value.split('.')
				// =2表示数据有小数位
				if (value2Array.length === 2) {
					floatPart = value2Array[1].toString() // 拿到小数部分
					if (floatPart.length === 1) {
						return intPartFormat + '.' + floatPart + '0'
					} else {
						return intPartFormat + '.' + floatPart
					}
				} else {
					return intPartFormat + floatPart
				}
      },
      pickTime(index = 0){
        if(index == 1){
            weui.datePicker({id:'last',title:'结束日期',start:new Date(this.early_time),end:new Date(),defaultValue:this.getTimeList(this.last_time), onConfirm:(value)=>{
                this.last_time = new Date(value[0].value+'-'+value[1].value+'-'+value[2].value)
                this.last_show_time = this.last_time
                this.getMore()
            }})
            return
        }
        let flag = false
        weui.datePicker({id:'eart',title:this.$t('main.startdate'),start:new Date(this.salesman_date), end:new Date(),defaultValue:this.getTimeList(this.early_time), onConfirm:(value)=>{
            this.early_time = new Date(value[0].value+'-'+value[1].value+'-'+value[2].value)
            flag = true
        },onClose:()=>{
            if(!flag){
                return
            }
            this.pickTime(1)
        }})
      }

    }
}
</script>

<style scoped>
.out{
    min-height: 100vh;
    box-sizing: border-box;
		background: url(../assets/img/index_bg.png) no-repeat center;
		background-size: cover;
        background-attachment: fixed;
    padding: 0.15rem;
}
.item{
    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.06rem;
}
.item_item{
    font-size: 0.14rem;
    box-shadow: 0 -1px 0 0 inset #e5e5e5;
    display: flex;
}
.item_top{
    box-sizing: border-box;
    padding: 0.1rem 0.15rem;
}
.item_list{
    width: 50%;
    box-sizing: border-box;
    padding: 0.18rem 0.15rem;
}
.button_list{
    box-sizing: border-box;
    padding: 0.15rem 0rem;
    display: flex;
    justify-content: space-between;
}
.button_list > div{
    width: 1.64rem;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.18rem;
    color: #fff;
    border-radius: 0.05rem;
}
.button_list > div:nth-child(1){
    background: #3e476f;
}
.button_list > div:nth-child(2){
    background: #9a2a25;
}
.list_top{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0.15rem;
    padding-top: 0.17rem;
    padding-bottom: 0.1rem;
}
.list_top > div{
    width: 33%;
    text-align: center;
    font-size: 0.14rem;
    color: #626262;
}
.list_item{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.1rem 0.15rem;
}
.list_item > div{
    width: 33%;
    text-align: center;
    font-size: 0.13rem;
    color: #000;
}
.more{
    text-align: center;
    font-size: 0.13rem;
    color: #000;
    padding: 0.12rem 0;
}
.password-box .agree-box-out {
		padding-left: 12%;
		margin-top: 0.075rem;
		margin-bottom: 0.075rem;
	}

	.password-box .pop-in .con .pass-input>div label {
		min-width: 12%;
	}

	.password-box .pop-in .con .err-tips {
		padding-left: 12%;
	}

	.password-box .pop-in .confirm-btn {
		margin: 0 auto;
	}
    .pop {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
	}
    .pop-in1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #edeae4;
		padding: 0.15rem 0.3rem;
        box-sizing: border-box;
		color: #171717;
		font-size: 0.12rem;
		width: 93%;
	}
    .pop-in1 .title {
		font-size: 0.18rem;
		display: flex;
		align-items: center;
	}

	.pop-in1 .title span {
		margin: 0 0.1rem;
	}

	.pop-in1 .title img {
		width: 0.18rem;
	}

	.pop-in1 .con .con-tips {
		margin: 0.2rem 0;
	}

	.pop-in1 .con .pass-input>div {
		display: flex;
		align-items: center;
		margin-bottom: 0.075rem;
	}

	.pop-in1 .con .pass-input>div label {
		min-width: 0.9rem;
		text-align: right;
	}

	.pop-in1 .con .pass-input>div input {
		height: 0.33rem;
		line-height: 0.33rem;
		font-size: 0.14rem;
		border-radius: 0.05rem;
		border: none;
		outline: none;
		padding-left: 0.1rem;
		width: 70%;
		box-sizing: border-box;
	}

	.pop-in1 .con .err-tips {
		padding-left: 20%;
		display: flex;
		align-items: center;
		color: #ff180e;
	}

	.pop-in1 .con .err-tips img {
		width: 0.13rem;
		margin-right: 0.1rem;
	}

	.pop-in1 .confirm-btn {
		font-size: 0.14rem;
		height: 0.33rem;
		border-radius: 0.05rem;
		width: 100%;
		margin: 0 auto;
		width: 70%;
		text-align: center;
		color: #ffffff;
		background: #9a2a25;
		line-height: 0.33rem;
		margin-top: 0.22rem;
	}
    .num{
        font-size: 0.16rem;
    }
    .num span{
        font-size: 0.12rem;
    }
    .bold > div{
        font-weight:bold;
        font-size:0.15rem!important;
    }
    .js_sp{
        justify-content: space-between;
    }
    .date_list{
        display: flex;
        align-items: center;
        box-shadow:  0 0 0 1px #626262;
        border-radius: 0.05rem;
        padding: 0 0.08rem;
    }
    .date_list svg{
        width: 0.15rem;
        height: 0.15rem;
        margin-right: 0.04rem;
    }
    .date_list > div{
        line-height: 0.15rem;
        box-sizing: border-box;        
    }
    .date_list > span{
        width: 0.2rem;
        text-align: center;
    }
    .close{
        position: absolute;
        right: 0.15rem;
        top: 0.15rem;
        width: 0.3rem;
        height: 0.3rem;
    }
</style>